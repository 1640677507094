import './App.css';

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { Typography } from '@mui/material';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAYHYNEgo4WEqWZsIqwz8Ap_1KJP7Yc-xU",
//   authDomain: "ignite-71815.firebaseapp.com",
//   projectId: "ignite-71815",
//   storageBucket: "ignite-71815.appspot.com",
//   messagingSenderId: "689992757496",
//   appId: "1:689992757496:web:d385d800e87fea9e558b0d",
//   measurementId: "G-911HE6Z66B"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <Typography variant="h1" className="Logo" sx={{fontWeight: "regular"}}>ignite</Typography>
      <Typography variant="h6" className="Subtitle">vfx pipeline and houdini toolset</Typography>
      <Typography variant="subtitle2" className="Subtitle">coming soon</Typography>
    </div>
  );
}

export default App;
